import React, { useEffect } from "react";
import GenericLoaderForm from "./GenericLoadersFormDD";

export default function SMAWrapperGenericLoaderForm({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, displayHeader, setIsRequired }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
  }, [setTaskConfig, studyType]);

  return (
    <GenericLoaderForm
      taskConfig={taskConfig}
      setTaskConfig={setTaskConfig}
      studyType={studyType}
      csv={csv}
      setCsv={setCsv}
      submitHandler={submitHandler}
      showTableDropDown={false}
      showProductionCycleDropDown={false}
      showInsertionTypeDropDown
      displayHeader={displayHeader}
      setIsRequired={setIsRequired}

    >
      {children}
    </GenericLoaderForm>
  );
}
